import React from 'react';

import SiteWrapper from '../components/SiteWrapper.js';
import SEO from '../components/seo.js';
import HeaderV2 from '../components/headerV2.js';

const Bio = ({ data }) => {
  return (
    <SiteWrapper>
      <SEO title="Bio" description="About me / contact info" />
      <HeaderV2 />
      <div style={{ display: 'flex', gap: 60 }}>
        <div style={{ flex: 1 }}>
          <p>
            Hello! I make things with sound, code and electronics, and am often
            inspired by geometry and natural patterns in my work.
          </p>
          <p>
            I develop music-making interfaces that encourage sonic exploration
            regardless of musical background.
          </p>
          <p>
            I work with{' '}
            <a href="https://noncoreprojector.com" target="blank">
              NonCoreProjector
            </a>{' '}
            to expose how data is interconnected in both logical and illogical
            ways.
          </p>
          <p>
            I release music as{' '}
            <a href="https://linktr.ee/jarz0" target="blank">
              Jarz0
            </a>{' '}
            and with the groups{' '}
            <a href="https://www.instagram.com/obstaclemusic/" target="blank">
              Obstacle
            </a>{' '}
            and{' '}
            <a href="https://www.instagram.com/custom_scenario/" target="blank">
              Custom Scenario
            </a>
            .
          </p>
          <p>
            In 2022 I graduated from <a href="https://itp.nyu.edu/itp/">ITP</a>{' '}
            at NYU.
          </p>
          <p>
            I'm currently helping build the future of creative exchange at{' '}
            <a href="https://baton.media/">Baton</a>.
          </p>
          {/* <p>
            Check out{' '}
            <a href="https://noncoreprojector.com" target="blank">
              NonCoreProjector
            </a>{' '}
            for web-based art installations or listen to my band{' '}
            <a href="https://obstaclemusic.com" target="blank">
              Obstacle
            </a>
            's latest release.
          </p> */}
        </div>
        <div style={{ flex: 1 }}>
          <p>
            Feel free to contact me at{' '}
            <span
              className="highlighted"
              style={{
                display: 'inline-block',
                padding: '0 3px',
                borderRadius: 2,
              }}
            >
              {'ejarz[at]pm.me'}
            </span>{' '}
          </p>
          <p>
            Or find me on{' '}
            <a href="https://github.com/ejarzo/" target="blank">
              GitHub
            </a>
            ,{' '}
            <a href="https://jarz0.bandcamp.com/" target="blank">
              {' '}
              Bandcamp
            </a>
            ,{' '}
            <a
              href="https://open.spotify.com/artist/0Si7RnGwNEWeH1AhYgzyW4"
              target="blank"
            >
              {' '}
              Spotify
            </a>
            ,{' '}
            <span style={{ display: 'inline-block' }}>
              <a
                href="https://www.linkedin.com/profile/view?id=AAIAABf1guIBOB8T0pedXQTO32b_DkZc_k93L6E&amp;trk=nav_responsive_tab_profile"
                target="blank"
              >
                LinkedIn
              </a>
              ,
            </span>{' '}
            and{' '}
            <a href="https://twitter.com/jarz_0" target="blank">
              Twitter
            </a>
            .
          </p>
        </div>
      </div>
    </SiteWrapper>
  );
};

export default Bio;
